import React, { useEffect } from "react";
import logo from "../../assets/Images/logo.png";
import { Menu, Popconfirm } from "antd";
// import ScalesIcon from '../Common/Icons/scales';
// import StarIcon from '../Common/Icons/star';
import ProfileIcon from "../Common/Icons/profile";
// import Languages from './Languages/Languages';
import style from "./style.module.scss";
import languageStyle from "./Languages/style.module.scss";
import {
  logOutUser,
  /*setActiveTab,*/ setMainLanguage,
} from "../../redux/Reducers/userReducer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import am from "../../assets/Images/flags/am.png";
import ru from "../../assets/Images/flags/ru.png";
import en from "../../assets/Images/flags/en.png";
import { IoIosLogOut } from "react-icons/io";

const InnerContainer = ({
  path,
  user,
  modalState,
  setModalState,
  insideMobileMenu,
  setVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //const compare = useSelector((state) => state.user.compare, shallowEqual); //for badge count
  //const favourites = useSelector((state) => state.user.favourites, shallowEqual); //for badge count
  const { i18n } = useTranslation();
  const { width } = useWindowDimensions();

  const lang = useSelector((state) => state.user.lang, shallowEqual);
  const langValue = lang[0].value;
  const changeLanguage = (name, value) => {
    const data = { name, value };
    i18n.changeLanguage(value);
    dispatch(setMainLanguage(data));
    setTimeout(() => {
      insideMobileMenu && setVisible(false);
    }, 500);
  };
  //fix for language migration
  if (langValue === "arm") {
    lang[0].value = "am";
    changeLanguage("Հայ", "am");
  }

  useEffect(() => {
    i18n.changeLanguage(langValue);
  }, [i18n, langValue]);
  const menuItems = [
    {
      label: (
        <Link
          to="/calculator"
          className={path === "calc" ? style.activeNav : style.navLink}>
          {t("calculator")}
        </Link>
      ),
      key: "calculator",
      className: style.navItem,
    },
    {
      label: (
        <Link
          to="/partners"
          className={path === "part" ? style.activeNav : style.navLink}>
          {t("nav_2")}
        </Link>
      ),
      key: "partner",
      className: style.navItem,
    },
    {
      label: (
        <Link
          to={{
            pathname: `/cars`,
          }}
          className={path === "part" ? style.activeNav : style.navLink}>
          {t("find_car")}
        </Link>
      ),
      key: "cars",
      className: style.navItem,
    },
    {
      label: (
        <Link
          to="/about-us"
          className={path === "about-us" ? style.activeNav : style.navLink}>
          {t("about_us")}
        </Link>
      ),
      key: "about-us",
      className: style.navItem,
    },
    {
      label: (
        <span className={languageStyle.span}>
          {lang[0].name}
          <div
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              marginLeft: 10,
              backgroundImage: `url(${(lang[0].value === "am" && am) ||
                (lang[0].value === "ru" && ru) ||
                (lang[0].value === "en" && en)
                })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </span>
      ),
      key: "language",
      children: [
        {
          label: (
            <span className={languageStyle.span}>
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  marginRight: 10,
                  backgroundImage: `url(${(lang[1].value === "am" && am) ||
                    (lang[1].value === "ru" && ru) ||
                    (lang[1].value === "en" && en)
                    })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              {lang[1].name}
            </span>
          ),
          key: "l-0",
          onClick: () => changeLanguage(lang[1].name, lang[1].value),
        },
        {
          label: (
            <span className={languageStyle.span}>
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  marginRight: 10,
                  backgroundImage: `url(${(lang[2].value === "am" && am) ||
                    (lang[2].value === "ru" && ru) ||
                    (lang[2].value === "en" && en)
                    })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              {lang[2].name}
            </span>
          ),
          key: "l2",
          onClick: () => changeLanguage(lang[2].name, lang[2].value),
        },
      ],
    },
  ];
  if (user && user.info) {
    menuItems.push({
      label: (
        <Link to="/profile">
          {width <= 768 ? (
            !user.info ? (
              t("profile")
            ) : (
              <p
                className={
                  (path === "prof" ? style.activeNav : style.profileName,
                    style.navLink)
                }>
                {user.info.userDetails ? user.info.userDetails.name : ""}
                <ProfileIcon className={path === "prof" && style.activeIcon} />
              </p>
            )
          ) : (
            <p
              className={
                (path === "prof" ? style.activeNav : style.profileName,
                  style.navLink)
              }>
              {user.info.userDetails.name}
            </p>
          )}
        </Link>
      ),
      key: "profile",
      className: style.navItem,
    });
    menuItems.push({
      label: (
        <span>
          {width <= 768 && t("exit")}

          <Popconfirm
            placement="bottom"
            title={t("pop_confirm")}
            icon={null}
            okText={t("yes")}
            cancelText={t("no")}
            overlayClassName={style.popConfirm}
            onConfirm={() => {
              dispatch(logOutUser(path));
            }}>
            <IoIosLogOut
              className={(style.ExitIcon, style.navLink)}
              color="#fff"
              style={{ justifyContent: "center", display: "flex" }}
              size={25}
            />
          </Popconfirm>
        </span>
      ),
      key: "log-out",
      className: style.navItem,
    });
  } else {
    menuItems.push({
      label: <hr />,
      key: "hr",
      style: {
        cursor: "default",
        pointerEvents: "none",
        display: width <= 768 && "none",
      },
    });
    menuItems.push({
      label: (
        <button
          className={style.login_btn}
          onClick={() =>
            setModalState({
              ...modalState,
              isVisible: true,
              initialForm: "signin",
            })
          }>
          {t("login")}
        </button>
      ),
      key: "sign-in",
      className: style.navItem,
    });
  }
  return (
    <div
      className={
        insideMobileMenu ? style.innerContainerMobile : style.innerContainer
      }>
      <Link
        to="/"
        className={style.logo}
        onClick={() => {
          setVisible && setVisible(false);
          dispatch({ type: "RESTORE_SEARCH_DEFAULT" });
          path === "" && window.location.reload();
        }}>
        <img src={logo} alt="logo" />
      </Link>
      <Menu
        items={menuItems}
        mode={insideMobileMenu ? "vertical" : "horizontal"}
        className={style.navMenu}
        triggerSubMenuAction="click"
        onClick={(item) => {
          user.info === null
            ? setVisible &&
            item.key !== "language" &&
            item.key !== "sign-up" &&
            item.key !== "sign-in" &&
            setVisible(false)
            : setVisible &&
            item.key !== "language" &&
            item.key !== "log-out" &&
            setVisible(false);
        }}
      />
    </div>
  );
};

export default InnerContainer;
