import React, { useRef, useEffect } from "react";
import "./style.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HomeSection = () => {
  const { t } = useTranslation();
  const detailsRef = useRef(null);

  const handleScrollDown = () => {
    if (detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="homeSectionWrapper">
      <div className="contentWrapper">
        <div className="mainSection">
          <div className="textWrapper">
            <div>
              <h1 className="mainText">
                {/* Ձեր մեքենաների տեղափոխությունը ցանկացած վայրից դեպի Հայաստան */}
                {t("why_pay_more")}
              </h1>
            </div>
            <Link to="/calculator">
              <button className="ctaButton">
                {t("calculate_cost")}
                <MdKeyboardArrowRight size={20} />
              </button>
            </Link>
          </div>
          <div className="carImageWrapper" />
        </div>
        <div className="carDetails" ref={detailsRef}>
          <div className="scrollDown" onClick={handleScrollDown}>
            <div>
              <div className="arrow"></div>
              <div className="arrow2"></div>
            </div>
            <p className="font">{t("scroll_down")}</p>
          </div>
          <div className="detailsRow">
            <span>Mercedes-Benz</span>
            <span>BMW</span>
            <span>Lexus</span>
            <a href="#" className="moreDetailsLink">
              {t("details")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
