import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  calculatePrice,
  loadLocations,
  loadFobs,
} from "../../../../api/requests";
import { calcCopartFob, calcIaaFob } from "../../../../Helpers/helpers";
import Button from "../../Buttons/Button";
import Auctions from "./Auctions/Auctions";
import Section1 from "./section1/Section1";
import Section2 from "./section2/Section2";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { MdArrowForwardIos } from "react-icons/md";

const CalculatorForm = ({ prices, setPrices, details, setDetails }) => {
  const { control, handleSubmit, errors, clearErrors, setValue, getValues } =
    useForm();
  const [locations, setLocations] = useState([]);
  const [activeAuction, setActiveAuction] = useState(1);
  const [fobs, setFobs] = useState([]);

  const handleAuctionSelect = useCallback((option) => {
    //set value for form
    setValue("auction", option);
    //reset location value
    setValue("location", false);
    //load locations

    setActiveAuction(option)
    loadLocations(option).then((res) => {
      setLocations(res.data.locations);
    });

    //change state
    const values = getValues();

    if (values.price?.length > 0) {
      let fob;
      if (values.auction === 1) {
        fob = calcIaaFob(values.price, fobs);
        setDetails({ ...details, auction: "IAAI" });
      } else if (values.auction === 2) {
        fob = calcCopartFob(values.price, fobs);
        setDetails({ ...details, auction: "COPART" });
      }
      setPrices({ ...prices, fob: fob, transport: null });
    }
  }, [details, fobs, getValues, prices, setDetails, setPrices, setValue]);

  //load default locations of IAA
  useEffect(() => {
    loadLocations(activeAuction).then((res) => {
      if (res && res.data) {
        setLocations(res.data.locations);
      }
    });
    loadFobs().then((res) => {

      if (res && res.data) {
        setFobs(res.data.fobs);
      }
    });
  }, [activeAuction]);

  const onSubmit = (data) => {
    const formData = { ...data, price: parseInt(data.price) };
    calculatePrice(formData).then((res) => {
      setPrices(res.data);
    });
  };
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="auction"
        control={control}
        defaultValue={1}
        rules={{ required: true }}
        as={
          <Auctions
            handleAuctionSelect={handleAuctionSelect}
            getValues={getValues}
            locations={locations}
            errors={errors}
          />
        }
      />
      <div className={style.inputsContainer}>
        <Section1
          fobs={fobs}
          locations={locations}
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          prices={prices}
          setPrices={setPrices}
          getValues={getValues}
          setValue={setValue}
          details={details}
          setDetails={setDetails}
        />
        <div className={style.lineStyle} />
        <Section2
          control={control}
          setValue={setValue}
          errors={errors}
          clearErrors={clearErrors}
          details={details}
          setDetails={setDetails}
        />
      </div>
      <Button
        innerText={t("calculate_cost")}
        type="submit"
        icon={<MdArrowForwardIos />}
      />
      <div className={style.noticeText}>{t("calculatorNoticeYear")}</div>
    </form>
  );
};

export default CalculatorForm;
