import React from 'react';
import Icon from '@ant-design/icons';

const MenuSVG = () => (
  <svg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='12' y='16' width='12' height='2' transform='rotate(-180 12 16)' fill='white' />
    <rect x='17' y='9' width='17' height='2' transform='rotate(-180 17 9)' fill='white' />
    <rect x='22' y='2' width='22' height='2' transform='rotate(-180 22 2)' fill='white' />
  </svg>
);

const MenuIcon = (props) => <Icon component={MenuSVG} {...props} />;

export default MenuIcon;
