import React from 'react';
import CalculatorForm from '../../../components/Common/Forms/CalculatorForm/CalculatorForm';
import style from './style.module.scss';

const CalculatorContainer = ({ prices, setPrices, details, setDetails }) => {
  return (
    <div className={style.calcContainer}>
      <CalculatorForm prices={prices} setPrices={setPrices} details={details} setDetails={setDetails} />
    </div>
  );
};

export default CalculatorContainer;
