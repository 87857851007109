import React, { useState } from "react";
import style from "./style.module.scss";
import LoginSignup from "../Common/Modals/LoginSignup/LoginSignup";
//import { shallowEqual, useSelector } from "react-redux";
import MenuWrapper from "./MenuWrapper";
import InnerContainer from "./InnerContainer";

const Navbar = ({ path, user, modalState, setModalState }) => {
  //const search = useSelector((state) => state.search, shallowEqual);
  const [visible, setVisible] = useState(false); //state for mobile menu
  // const url = search
  //   .map((obj) => {
  //     return `${obj.name}=${obj.defaultValue}`;
  //   })
  //   .join("&");
  return (
    <div
      className={style.headerWpaerr}
      style={{
        display:
          (path === "logi" && "none") ||
          (path === "sign" && "none") ||
          (path === "rese" && "none"),
      }}>
      <div className={style.header}>
        <MenuWrapper
          path={path}
          user={user}
          modalState={modalState}
          setModalState={setModalState}
          visible={visible}
          setVisible={setVisible}
        />

        <InnerContainer
          path={path}
          user={user}
          modalState={modalState}
          setModalState={setModalState}
          setVisible={setVisible}
        />
        <LoginSignup modalState={modalState} setModalState={setModalState} />
      </div>
    </div>
  );
};

export default Navbar;
