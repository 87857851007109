import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import style from "../style.module.scss";
import { useTranslation } from "react-i18next";

import Description from "../Description/Description";
import { formatPrice } from "../../../../general-functions";
import { MdFileCopy } from "react-icons/md";

const CarInfo = () => {
  const { t } = useTranslation();
  const carDetails = useSelector((state) => state.carDetails, shallowEqual);
  const text = carDetails.customText;
  const make = carDetails.carMake ? carDetails.carMake.name : "-";
  const model = carDetails.carModel ? carDetails.carModel.name : "-";
  const bodyType = carDetails.carBodyType ? carDetails.carBodyType.name : null;
  const gearBox = carDetails.carGearBox ? carDetails.carGearBox.name : "-";
  const extColor = carDetails.carExtColor ? carDetails.carExtColor.name : "-";
  const intColor = carDetails.carIntColor ? carDetails.carIntColor.name : "-";

  const {
    year,
    price,
    mileage,
    steeringWheel,
    engine,
    cleanCar,
    exchange,
    partSelling,
    vin,
    volume,
    wheelDrive,
    arrivedStatus,
  } = carDetails;

  const copyVin = () => {
    var copyText = document.getElementById("vinCode");

    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(copyText);
    selection.removeAllRanges();
    selection.addRange(range);

    navigator.clipboard.writeText(vin);
  }
  
  return (
    <div className={style.carInfoContainer}>
      <div>
        <div className={style.title}>
          <h1>
            {year ? year : "-"} {make ? make : "-"} {model ? model : "-"}
          </h1>
          <div className={style.price}>
            {}
            <h1>{price ? `${formatPrice(price)}` : t("onAgree")}</h1>
          </div>
          <h3 className={style.priceMobile}>
            {price ? `${formatPrice(price)}` : t("onAgree")}
          </h3>
          <hr />
          <p></p>
          <h3 className={style.yearMobile}>{year ? year : "-"}</h3>
        </div>
        <div className={style.labelsMobile}>
          {cleanCar === 2 && <p>{t("NotCustomCleared")}</p>}
          {(arrivedStatus === 0 || arrivedStatus === 1) && (
            <p>{t("soldOnWay")}</p>
          )}
          {cleanCar === 1 && <p>{t("CustomCleared")}</p>}
          {exchange === 1 && <p>{t("Exchange")}</p>}
          {partSelling === 1 && <p>{t("ByParts")}</p>}
        </div>
        <div className={style.info}>
          {vin && (
            <div>
              <span>VIN</span>
              <h2>
                <MdFileCopy onClick={copyVin} size={20} />
                <span id='vinCode'>{vin}</span>
              </h2>
            </div>
          )}
          {bodyType && (
            <div>
              <span>{t("body_type")}</span>
              <h2>{bodyType ? t(`bodyType_${bodyType}`) : t("Other")}</h2>
            </div>
          )}
          {wheelDrive && (
            <div>
              <span>{t("wheelDrive")}</span>
              <h2>
                {(wheelDrive === 1 && t("frontD")) ||
                  (wheelDrive === 2 && t("backD")) ||
                  (wheelDrive === 3 && t("fxf"))}
              </h2>
            </div>
          )}
          <div>
            <span>{t("placeholder_engine")}</span>
            <h2>
              {(engine === 1 && t("gasoline")) ||
                (engine === 2 && t("diesel")) ||
                (engine === 3 && t("propane")) ||
                (engine === 4 && t("electric")) ||
                (engine === 5 && t("hybrid")) ||
                (engine === 6 && t("hydrogen"))}
              &nbsp;{volume / 1000} L
            </h2>
          </div>
          <div>
            <span>{t("placeholder_ext_color")}</span>
            <h2>{extColor ? t(`${extColor}`) : "-"}</h2>
          </div>
          <div>
            <span>{t("placeholder_mileage")}</span>
            <h2>
              {`${mileage}`} {t("miles")} /{`${(mileage * 1.60934).toFixed(0)}`}{" "}
              {t("km")}
            </h2>
          </div>
          <div>
            <span>{t("placeholder_gearBox")}</span>
            <h2>{gearBox ? t(`gearBox_${gearBox}`) : "-"}</h2>
          </div>
          <div>
            <span>{t("placeholder_int_color")}</span>
            <h2>{intColor ? t(`${intColor}`) : "-"}</h2>
          </div>
          {steeringWheel && (
            <div>
              <span>{t("placeholder_steeringWheel")}</span>
              <h2>{steeringWheel === 1 ? t("left") : t("right")}</h2>
            </div>
          )}
        </div>
        <div className={style.lineStyle} />
        <div className={style.labels}>
          {cleanCar === 2 && <p>{t("NotCustomCleared")}</p>}
          {(arrivedStatus === 0 || arrivedStatus === 1) && (
            <p>{t("soldOnWay")}</p>
          )}
          {cleanCar === 1 && <p>{t("CustomCleared")}</p>}
          {exchange === 1 && <p>{t("Exchange")}</p>}
          {partSelling === 1 && <p>{t("ByParts")}</p>}
        </div>
      </div>
      <div />
      <h1 style={{ padding: "2% 0 0 0", fontSize: 28, fontWeight: "900" }}>
        Հավելյալ տեղեկություն
      </h1>
      {carDetails.customText && <Description text={text} />}
      {/*<ButtonsWrapper carDetails={carDetails} id={id} />*/}
    </div>
  );
};

export default CarInfo;
