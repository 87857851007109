import { Carousel } from "antd";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
// import SliderNextIcon from "../../../../components/Common/Icons/sliderNext";
// import SliderNextIconMobile from "../../../../components/Common/Icons/sliderNextMobile";
// import SliderPrevIcon from "../../../../components/Common/Icons/sliderPrev";
// import SliderPrevIconMobile from "../../../../components/Common/Icons/sliderPrevMobile";
import SliderModal from "../../../../components/Common/Modals/SliderModal/SliderModal";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import style from "../style.module.scss";
// import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:4000";

const CarouselContainer = () => {
  const { width } = useWindowDimensions();
  const [isVisible, setIsVisible] = useState(false);
  const [startingNumber, setStartingNumber] = useState(0); //to determine starting position in modal slider
  const slider = useRef();
  const carDetails = useSelector((state) => state.carDetails);
  let firstMediaId = null;
  // get first image id
  if (carDetails.firstMedia && carDetails.firstMedia.length) {
    firstMediaId = carDetails.firstMedia[0].carImage.id;
  }

  const images = carDetails.carMedia.map((media) => {
    return {
      src: `${BASE_URL}${media.carImage.location}${media.carImage.name}medium.webp`,
      id: media.carImage.id,
    };
  });
  const smallImages = carDetails.carMedia.map((media) => {
    return {
      src: `${BASE_URL}${media.carImage.location}${media.carImage.name}medium.webp`,
      id: media.carImage.id,
    };
  });
  const largeImages = carDetails.carMedia.map((media) => {
    return {
      src: `${BASE_URL}${media.carImage.location}/${media.carImage.name}large.webp`,
      id: media.carImage.id,
    };
  });
  // reorder images to start from firstMediaId
  if (firstMediaId) {
    const firstMediaIndex = images.findIndex(
      (image) => image.id === firstMediaId
    );
    const firstMedia = images.splice(firstMediaIndex, 1);
    const firstSmallMedia = smallImages.splice(firstMediaIndex, 1);
    const firstLargeMedia = largeImages.splice(firstMediaIndex, 1);
    images.unshift(firstMedia[0]);
    smallImages.unshift(firstSmallMedia[0]);
    largeImages.unshift(firstLargeMedia[0]);
  }

  const handleClick = (e) => {
    ////console.log(e.target.tabIndex);
    setStartingNumber(e.target.tabIndex);
    width > 768 && setIsVisible(true);
  };

  return (
    <div className={style.carouselContainer}>
      <div className={style.bigPic}>
        <Carousel
          adaptiveHeight={true}
          dots={false}
          infinite={false}
          ref={(ref) => {
            slider.current = ref;
          }}>
          {images.map((image, i) => (
            <div
              key={i}
              onClick={() => {
                setStartingNumber(i);
                width > 768 && setIsVisible(true);
              }}>
              <div
                className={style.carouselItem}
                style={{
                  backgroundImage: `url(${image.src})`,
                }}></div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className={style.smallPicsWrapper}>
        {smallImages.map((image, i) => (
          <div
            tabIndex={i}
            onClick={handleClick}
            key={i}
            style={{
              background: `url(${image.src}) center center no-repeat`,
            }}
            className={style.smallPicsWrapperItem}>
            {/*{images.length > 3 && i === 2 ? (*/}
            {/*  <div className={style.third}>*/}
            {/*    <h1>+{images.length - 3}</h1>*/}
            {/*  </div>*/}
            {/*) : null}*/}
          </div>
        ))}
      </div>
      <SliderModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        images={largeImages}
        startingNumberProp={startingNumber}
        setStartingNumber={setStartingNumber}
      />
    </div>
  );
};

export default CarouselContainer;
