import { Checkbox } from "antd";
import React, { Fragment } from "react";
import { Controller } from "react-hook-form";
import years from "../../../../../assets/years";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import SectionController from "./SectionController";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

const Section2 = ({
  control,
  setValue,
  errors,
  clearErrors,
  details,
  setDetails,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;
  const YearFieldStyle = {
    marginRight: isMobile ? "5px" : 0,
    marginTop: isMobile ? "5%" : 0,
    width: isMobile ? "100%" : "31%",
  };

  const TruckFieldCheckStyle = {
    marginRight: isMobile ? "5px" : 0,
    width: isMobile ? "100%" : "47%",
  };

  const CarScaleFieldStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexDirection: isMobile ? "column" : "row",
  };

  const EngineFieldStyle = {
    marginRight: isMobile ? "5px" : 0,
    width: isMobile ? "100%" : "47%",
  };

  const flex = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: isMobile ? "column" : "row",
  };

  const Width_50 = {
    width: isMobile ? "100%" : "50%",
  };

  const WidthFull = {
    width: "100%",
  };
  const inputs = [
    {
      multiple: true,
      style: flex,
      name: "year",
      placeholder: t("select_year"),
      fields: {
        year: {
          key: "year",
          label: "",
          name: "year",
          options: years,
          style: YearFieldStyle,
          required: true,
          placeholder: t("select_year"), // Add placeholder for month
        },
        month: {
          key: "month",
          label: "",
          name: "month",
          options: [
            { value: 1, name: t("jan") + " (01)" },
            { value: 2, name: t("feb") + " (02)" },
            { value: 3, name: t("mar") + " (03)" },
            { value: 4, name: t("apr") + " (04)" },
            { value: 5, name: t("may") + " (05)" },
            { value: 6, name: t("jun") + " (06)" },
            { value: 7, name: t("jul") + " (07)" },
            { value: 8, name: t("aug") + " (08)" },
            { value: 9, name: t("sep") + " (09)" },
            { value: 10, name: t("oct") + " (10)" },
            { value: 11, name: t("nov") + " (11)" },
            { value: 12, name: t("dec") + " (12)" },
          ],
          style: YearFieldStyle,
          required: false,
        },
        day: {
          key: "day",
          label: "",
          name: "day",
          options: details.monthDays,
          style: YearFieldStyle,
          required: false,
        },
      },
    },
    {
      multiple: true,
      style: CarScaleFieldStyle,
      fields: {
        engine: {
          label: t("carScale"),
          style:
            details.carScale === "truck" ? TruckFieldCheckStyle : WidthFull,

          defaultValue: details.carScale,
          name: "carScale",
          key: "carScale",
          options: [
            { value: "sedan", name: t("sedan") },
            { value: "truck", name: t("truck") },
            { value: "quadBike", name: t("quadBike") },
            { value: "motorcycle", name: t("motorcycle") },
            { value: "snowmobile", name: t("snowmobile") },
          ],
          required: true,
        },
        tonnage: {
          label: t("tonnage"),
          name: "tonnage",
          key: "tonnage",
          options: [
            {
              value: 5,
              name: t("till5tonn"),
            },
            {
              value: 20,
              name: t("from5till20tonn"),
            },
            {
              value: 30,
              name: t("moreThan20tonn"),
            },
          ],
          required: details.carScale === "truck",
          hide: details.carScale !== "truck",
          defaultValue: 5,
          style: Width_50,
        },
      },
    },
    {
      multiple: true,
      style: flex,
      fields: {
        engine: {
          label: t("placeholder_engine"),
          name: "engine",
          defaultValue: details.engine,
          style: details.engine !== "electric" ? EngineFieldStyle : WidthFull,
          key: "engine",
          options: [
            { value: "gasoline", name: t("gasoline") },
            {
              value: "diesel",
              name: t("diesel"),
              disabled: ["motorcycle", "quadBike"].includes(details.carScale),
            },
            {
              value: "electric",
              name: t("electric"),
              disabled: ["truck", "motorcycle", "quadBike"].includes(
                details.carScale
              ),
            },
            {
              value: "hybrid",
              name: t("hybrid"),
              disabled: ["truck", "motorcycle", "quadBike"].includes(
                details.carScale
              ),
            },
          ],
          required: true,
        },
        engineVol: {
          label: t("engine_vol"),
          style: EngineFieldStyle,
          name: "engineVol",
          key: "engineVol",
          required: details.engine !== "electric",
          hide: details.engine === "electric",
          defaultValue: details.engine === "electric" ? 0 : null,
        },
      },
    },
  ];
  const carType = { label: t("ground_clearance"), name: "carType" };
  const nash = { label: t("nash"), name: "nash" };
  const hasR = { label: t("hasR"), name: "hasR" };
  const enableInsurance = { label: t("insurance"), name: "enableInsurance" };

  const handleClearance = (value) => {
    setDetails({ ...details, carType: value });
  };
  const handleNash = (value) => {
    setDetails({ ...details, nash: value });
  };

  const handleHasR = (value) => {
    setDetails({ ...details, hasR: value });
  };

  return (
    <div className={style.section2Container}>
      {inputs.map((input, index) => {
        return input.multiple ? (
          <Fragment key={index + "f"}>
            {input.hasOwnProperty("label") &&
              input.label !== "" &&
              input.name && (
                <label key={index + "l"} htmlFor={input.name}>
                  {input.label}{" "}
                  <span style={{ color: "red", fontSize: "22px" }}>*</span>
                </label>
              )}
            <div style={input.style} key={index + "d"}>
              {SectionController({
                control,
                setValue,
                errors,
                clearErrors,
                details,
                setDetails,
                fields: Object.values(input.fields),
              })}
            </div>
          </Fragment>
        ) : (
          SectionController({
            control,
            setValue,
            errors,
            clearErrors,
            details,
            setDetails,
            fields: [input],
          })
        );
      })}
      <div style={{ padding: "18px 0" }}></div>
      <div className={style.bottomSection}>
      <Controller
        name={enableInsurance.name}
        control={control}
        defaultValue={false}
        render={({ onChange, value }) => (
          <div>
            <Checkbox
              checked={value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              className="checkbox-block"
              >
              {enableInsurance.label} 
            </Checkbox>
          </div>
        )}
      />
      {details.carScale === "sedan" &&
        !["hybrid", "electric"].includes(details.engine) && (
          <Controller
            name={carType.name}
            control={control}
            defaultValue={false}
            render={({ onChange, value }) => (
              <div>
                <Checkbox
                  checked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleClearance(e.target.checked);
                  }}
                  className="checkbox-block"
                  >
                  {carType.label}
                </Checkbox>
              </div>
            )}
          />
        )}
      {details.carScale === "sedan" && details.engine === "hybrid" && (
        <Controller
          name={nash.name}
          control={control}
          defaultValue={details.nash}
          render={({ onChange, value }) => (
            <div style={{ textAlign: "left" }}>
              <Checkbox
                checked={value}
                onChange={(e) => {
                  onChange(e.target.checked);
                  handleNash(e.target.checked);
                }}>
                <span style={{ textAlign: "left", fontSize: "12px" }}>
                  {nash.label}
                </span>
              </Checkbox>
            </div>
          )}
        />
      )}
      {details.carScale === "quadBike" && (
        <Controller
          name={hasR.name}
          control={control}
          defaultValue={details.hasR}
          render={({ onChange, value }) => (
            <div>
              <Checkbox
                checked={value}
                onChange={(e) => {
                  onChange(e.target.checked);
                  handleHasR(e.target.checked);
                }}>
                {hasR.label}
              </Checkbox>
            </div>
          )}
        />
      )}
      </div>
    </div>
  );
};
// const YearFieldStyle = {
//   marginRight: "5px",
//   width: "31%",
// };
// const TruckFieldCheckStyle = {
//   marginRight: "5px",
//   width: "47%",
// };
// const CarScaleFieldStyle = {
//   display: "flex",
//   justifyContent: "space-between",
//   width: "100%",
// };
// const EngineFieldStyle = {
//   marginRight: "5px",
//   width: "47%",
// };
// const flex = { display: "flex", justifyContent: "space-between" };
// const Width_50 = { width: "50%" };
// const WidthFull = { width: "100%" };

export default Section2;
