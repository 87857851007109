import React from "react";

const AuctionImg = ({ img, name, id, handleClick, style }) => {
  return (
    <button onClick={(e) => handleClick(e, id)} className={style}>
      <div>
        <img src={img} alt={name} />
      </div>
    </button>
  );
};

export default AuctionImg;
