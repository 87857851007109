import React from "react";
import { formatPrice } from "../../../../general-functions";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:4000";

const CarouselItem = ({ product }) => {
  const history = useHistory();

  const { carMake, carModel, carMedia, price, year } = product;
  const { t } = useTranslation();

  const imageUrl =
    carMedia.length > 0 &&
    BASE_URL +
      carMedia[0].carImage.location +
      carMedia[0].carImage.name +
      "medium.webp";
  const goToCar = (e) => {
    e.stopPropagation();
    history.push(`/car/${product.id}`);
  };
  return (
    <div className="product" onClick={goToCar}>
      <div className="product-top">
        <div className="product-image">
          <img src={imageUrl} alt={`${carMake.name} ${carModel.name}`} />
        </div>
      </div>
      <div className="product-bottom">
        <p className="product-info">
          <span className="product-name">
            {year + ` ${carMake.name} ${carModel.name}`}
          </span>
          {(price && (
            <span className="price-prices">{formatPrice(price)}</span>
          )) ||
            t("onAgree")}
        </p>
      </div>
    </div>
  );
};

export default CarouselItem;
