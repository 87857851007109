import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import FirstSection from "./FirstSection/FirstSection";
import SecondSection from "./SecondSection/SecondSection";
// import disableScroll from "disable-scroll";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setCarDetails } from "../../redux/Reducers/singleCarReducer";
import Loader from "../../components/Common/Loader/Loader";
import NotFound from "../NotFound/NotFound";
// import { useTranslation } from "react-i18next";

const SingleCar = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const carDetails = useSelector((state) => state.carDetails, shallowEqual);
  const { id } = props.match.params;
  const dispatch = useDispatch();
  //const { t } = useTranslation();
  useEffect(() => {
    //
    dispatch(setCarDetails(id, setIsVisible));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [dispatch, id]);
  if (isVisible) {
    return <Loader isVisible={isVisible} />;
  }
  return (
    <div className={style.pageContainer}>
      {carDetails.shopStatus === 1 ? (
        <React.Fragment>
          <div className={style.screenWrapper}>
            <FirstSection />
            <SecondSection />
          </div>
        </React.Fragment>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default SingleCar;
